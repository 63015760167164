import React from 'react'
import { graphql } from 'gatsby'
import ImageGallery from 'react-image-gallery'
import Layout from '../components/layout'
import SEO from '../components/seo'

import './realisaties.css'

const RealisatiesPage = ({ data }) => {
  // Fetch the categories ex. "Verwarming", "Sanitair"...
  // A new added category will be updates automatically
  const categories = data.allContentfulRealisatie.edges.map(
    node => node.node.naam
  )

  const allPictures = data.allContentfulRealisatie.edges

  return (
    <Layout>
      <SEO
        title="Realisaties"
        keywords={[
          `Yves De Vrient`,
          `Loodgieter Yves De Vrient`,
          `De Vrient`,
          `Vrient`,
          `Loodgieter De Vrient`,
          `loodgieter`,
          `loodgieter Kruisem`,
          `loodgieter Zingem`,
          `Loodgieter Oudenaarde`,
          `Yves De Vrient Realisaties`,
          `realisaties`,
          `projecten`,
          `werken`,
        ]}
      />
      <h1>Realisaties</h1>
      <p>
        U kunt door de foto's navigeren door op de links en rechtspijlen te
        drukken
      </p>

      {/* Construct a slideshow for every type of pictures */}
      {categories.map(category => {
        let categoryPictures = allPictures.filter(
          picture => picture.node.naam === category
        )

        const categoryId = categoryPictures[0].node.id
        // Always one node
        categoryPictures = categoryPictures[0].node.fotos

        const categoryPicturesFormatted = categoryPictures.map(picture => {
          // format for ImageGallery
          return {
            original: picture.file.url,
            thumbnail: picture.file.url,
          }
        })

        return (
          <div key={category}>
            <h2>{category}</h2>
            <ImageGallery
              key={categoryId}
              items={categoryPicturesFormatted}
              lazyLoad={true}
              showPlayButton={false}
            />
          </div>
        )
      })}
    </Layout>
  )
}

export default RealisatiesPage

export const pageQuery = graphql`
  {
    allContentfulRealisatie(sort: { fields: createdAt, order: ASC }) {
      edges {
        node {
          id
          naam
          fotos {
            title
            file {
              url
            }
          }
        }
      }
    }
  }
`
